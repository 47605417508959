import { lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { RouteConstants } from "./RouteConstants";

import RequiredAuth from "components/common/RequiredAuth";
import Unauthorized from "components/common/Unauthorized";
import _404 from "components/common/_404";

// Use lazy loaded pages

const Login = lazy(() => import("modules/login/pages"));
const ForgotPassword = lazy(() => import("modules/forgotPassword/pages"));
const ResetPassword = lazy(() => import("modules/resetPassword/pages"));
const Ingredients = lazy(() => import("modules/ingredients/pages"));
const Recipes = lazy(() => import("modules/recipes/pages"));
const Tags = lazy(() => import("modules/category/pages/ShoppingAisles"));
const AddEditRecipe = lazy(() =>
  import("modules/recipes/components/AddEditRecipe/index")
);
const Challenges = lazy(() => import("modules/challenges/pages"));
const AddEditChallenges = lazy(() =>
  import("modules/challenges/components/AddEditChallenges")
);

const Products = lazy(() => import("modules/products/pages"));
const AddEditProduct = lazy(() =>
  import("modules/products/components/AddEditProduct")
);

const PushNotifications = lazy(() => import("modules/pushNotifications/pages"));
const Users = lazy(() => import("modules/users/pages"));
const Faq = lazy(() => import("modules/faq/page"));

const PromotionalOffer = lazy(() => import("modules/promotionalOffer/pages"));
const AddPromotionalOffer = lazy(() =>
  import("modules/promotionalOffer/components/addPromotionalOffer")
);

const Subscribers = lazy(() => import("modules/subscribers/pages"));

const routesConfig = {
  common: [
    {
      path: "unauthorized",
      component: Unauthorized,
    },
    { path: "*", component: _404 },
  ],
  private: [
    {
      path: "/",
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <Ingredients />,
        },
      ],
    },
    {
      path: RouteConstants.ingredients,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <Ingredients />,
        },
      ],
    },
    {
      path: RouteConstants.recipes,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <Recipes />,
        },
      ],
    },
    {
      path: RouteConstants.newRecipes,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <AddEditRecipe />,
        },
      ],
    },
    {
      path: RouteConstants.editRecipes,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <AddEditRecipe />,
        },
      ],
    },
    {
      path: RouteConstants.categories,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <Tags />,
        },
      ],
    },
    {
      path: RouteConstants.challenges,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <Challenges />,
        },
      ],
    },
    {
      path: RouteConstants.newChallenges,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <AddEditChallenges />,
        },
      ],
    },
    {
      path: RouteConstants.editChallenges,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <AddEditChallenges />,
        },
      ],
    },
    {
      path: RouteConstants.products,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <Products />,
        },
      ],
    },
    {
      path: RouteConstants.addProduct,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <AddEditProduct />,
        },
      ],
    },
    {
      path: RouteConstants.editProduct,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <AddEditProduct />,
        },
      ],
    },
    // {
    //   path: RouteConstants.videos,
    //   component: RequiredAuth,
    //   children: [
    //     {
    //       index: true,
    //       component: () => <Videos />,
    //     },
    //   ],
    // },

    {
      path: RouteConstants.notifications,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <PushNotifications />,
        },
      ],
    },

    {
      path: RouteConstants.promotionalOffer,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <PromotionalOffer />,
        },
      ],
    },
    {
      path: RouteConstants.addPromotionalOffer,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <AddPromotionalOffer />,
        },
      ],
    },
    {
      path: RouteConstants.subscribers,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <Subscribers />,
        },
      ],
    },
    {
      path: RouteConstants.editPromotionalOffer,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <AddPromotionalOffer />,
        },
      ],
    },

    {
      path: RouteConstants.users,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <Users />,
        },
      ],
    },
    {
      path: RouteConstants.faq,
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: () => <Faq />,
        },
      ],
    },
  ],
  public: [
    {
      path: RouteConstants?.login,
      component: Outlet,
      children: [{ index: true, component: () => <Login /> }],
    },
    {
      path: RouteConstants?.forgotPassword,
      component: Outlet,
      children: [{ index: true, component: () => <ForgotPassword /> }],
    },
    {
      path: RouteConstants?.resetPassword,
      component: Outlet,
      children: [{ index: true, component: () => <ResetPassword /> }],
    },
    {
      path: "*",
      component: <Navigate to={RouteConstants.dashboard} />,
    },
  ],
};

export default routesConfig;
