export const RouteConstants = {
  login: "login",
  forgotPassword: "forgot-password",
  resetPassword: "reset-password",
  ingredients: "/ingredients",
  recipes: "/recipes",
  newRecipes: "/new-recipes",
  editRecipes: "/edit-recipes",
  newChallenges: "/new-meal-Plan",
  editChallenges: "/edit-meal-Plan",
  challenges: "/meal-Plans",
  products: "/products",
  addProduct: "/add-product",
  editProduct: "/edit-product",
  videos: "/videos",
  promotionalOffer: "/promotional-offer",
  addPromotionalOffer: "/add-promotional-offer",
  editPromotionalOffer: "/edit-promotional-offer",
  subscribers: "/subscribers",
  notifications: "/push-notifications",
  users: "/users",
  faq: "/faq",
  categories: "/categories",
};
